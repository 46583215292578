<template>
  <div id="contact-form-container">
    <form @submit.prevent="onSubmit">
      <div class="input-container">
        <font-awesome-icon :icon="['fas', 'user-circle']" size="2x" />
        <div class="field">
          <input
            v-model="name"
            type="text"
            name="name"
            autocomplete="name"
            required
            @focus="addClassToLabel"
            @blur="addClassToLabel"
          />
          <label for="name">Full Name</label>
        </div>
      </div>
      <div class="input-container">
        <font-awesome-icon :icon="['far', 'envelope']" size="2x" />
        <div class="field">
          <input
            v-model="email"
            type="email"
            name="email"
            autocomplete="email"
            required
            @focus="addClassToLabel"
            @blur="addClassToLabel"
          />
          <label for="email">Email Address</label>
        </div>
      </div>
      <div class="input-container">
        <font-awesome-icon :icon="['fas', 'phone']" size="2x" />
        <div class="field">
          <input
            v-model="phone"
            type="number"
            name="telephone"
            autocomplete="tel"
            required
            @focus="addClassToLabel"
            @blur="addClassToLabel"
          />
          <label for="telephone">Phone No.</label>
        </div>
      </div>
      <div class="input-container">
        <font-awesome-icon :icon="['fas', 'question-circle']" size="2x" />
        <div class="field">
          <select name="type-of-query" v-model="infoFrom" required>
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="Word of mouth">Word of mouth</option>
            <option value="Others">Others</option>
          </select>
        </div>
      </div>

      <div id="email-checkbox">
        <input
          v-model="newsletter"
          type="checkbox"
          name="newsletter"
          id="newsletter"
        />
        <label class="custom-checkbox" for="newsletter">
          Keep me updated on future products/workshops.
        </label>
      </div>
      <div id="contact-submission-btn">
        <button type="submit">
          <span v-if="!submitting">Submit</span>
          <span v-else>Loading...</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "workshop-form",
  props: ["workshopValueModal", "dateTimeString"],
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      infoFrom: null,
      newsletter: null,
      submitting: false,
    };
  },
  methods: {
    async onSubmit() {
      this.submitting = true;
      const res = await this.$http
        .post("userworkshop/register", {
          json: {
            workshopId: this.workshopValueModal.workshopId,
            name: this.name,
            email: this.email,
            contactNumber: this.phone,
            infoFrom: this.infoFrom,
            newsletter: this.newsletter,
          },
        })
        .json();
      if (res) {
        this.$emit("submit-result", res);
      }
    },
    addClassToLabel(e) {
      if (e.type === "focus") {
        document
          .querySelector(`label[for="${e.target.name}"]`)
          .classList.add("active");
      } else {
        if (e.target.value !== "") {
          //If it is not an empty String
          document
            .querySelector(`label[for="${e.target.name}"]`)
            .classList.add("active");
        } else {
          document
            .querySelector(`label[for="${e.target.name}"]`)
            .classList.remove("active");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#contact-form-container {
  text-align: left;
  form {
    padding: 4rem;
    padding-top: 0.83em;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  .input-container {
    display: flex;
    margin: 1rem 0;
    background-color: #f4f4f0;
    padding: 1.5rem 1rem 0.8rem 1rem;
    border-radius: 20px;
    svg {
      padding: 10px 10px 10px 5px;
      color: #cccccc;
    }
  }
  input:read-only {
    .input-container {
      background-color: rgb(185, 185, 185);
    }
  }

  select {
    width: 100%;
    border: none;
    outline: none;
    background-color: #f4f4f0;
    &:focus {
      outline: #cccccc solid 1px;
    }
  }

  #contact-submission-btn {
    font-size: 1.8rem;
    text-align: center;
    button {
      cursor: pointer;
      outline: none;
      border: none;
      background-color: #107e79;
      color: #f4f4f0;
      font-weight: bold;
      width: 80%;
      padding: 1.5rem;
      border-radius: 20px;
      transition: background-color 0.3s ease;
      -webkit-transition: background-color 0.3s ease;
      -moz-transition: background-color 0.3s ease;
      -ms-transition: background-color 0.3s ease;
      -o-transition: background-color 0.3s ease;

      &:hover {
        background-color: #0f706b;
      }
    }
  }
}

#email-checkbox {
  display: block;
  width: 100%;
  padding: 1rem 0.2rem;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    font-size: 1.2rem;
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid #cccccc;
      background-color: #f4f4f0;
      border-radius: 7px;
      padding: 1rem;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 1rem;
      transition: background-color 0.3s ease;
      -webkit-transition: background-color 0.3s ease;
      -moz-transition: background-color 0.3s ease;
      -ms-transition: background-color 0.3s ease;
      -o-transition: background-color 0.3s ease;
    }
  }

  input:checked {
    + label::after {
      content: "";
      display: block;
      position: absolute;
      top: -3px;
      left: 10px;
      width: 12px;
      height: 20px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    + label::before {
      background-color: #107e79;
      border: 1px solid #107e79;
    }
  }
}

.field {
  position: relative;
  display: flex;
  width: 100%;
  margin-left: 0.5rem;
  textarea {
    resize: none;
  }

  input,
  textarea {
    background: none;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    &:focus {
      outline: none;
    }
  }

  label {
    color: #9c9ca8;
    font-size: 1rem;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 8px;
    top: 15px;
    transition: 300ms ease all;
    &.active {
      top: -14px;
      font-size: 0.8rem;
    }
  }
}
</style>
