<template>
  <div id="workshop-item-content">
    <b id="workshop-item-content-title">{{ workshop.title }}</b>
    <p>{{ dateTimeString }}</p>
    <p>{{ workshop.location }}</p>
    <p>{{ workshopDescriptionArray[0] }}</p>
    <ul
      v-for="description in workshopDescriptionArray.slice(1)"
      :key="description"
    >
      <li>{{ description }}</li>
    </ul>
    <button class="button closed-button" v-if="isClosed()">Closed</button>
    <button class="button register-button" v-else @click="onButtonClick">
      Register
    </button>
  </div>
</template>
<script>
import * as dayjs from "dayjs";
export default {
  name: "workshop-item",
  props: ["workshop"],
  methods: {
    onButtonClick() {
      this.$emit("open-modal", this.workshop, this.dateTimeString);
    },
    getFormattedDateTime() {
      const startDateTime = dayjs(this.workshop.datetime).toDate();
      const endTime = dayjs(this.workshop.endDateTime)
        .toDate()
        .toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
      const startDate = startDateTime.toLocaleDateString("en-SG", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      });
      const startTime = startDateTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      this.dateTimeString = startDate + " " + startTime + "-" + endTime;
    },
    isClosed() {
      return dayjs(this.workshop.datetime).isBefore(dayjs());
    },
    getFormatDescription() {
      this.workshopDescriptionArray = this.workshop.description.split("~");
    },
  },
  mounted() {
    this.getFormattedDateTime();
    this.getFormatDescription();
  },
  data() {
    return {
      dateTimeString: "",
      workshopDescriptionArray: [],
    };
  },
};
</script>
<style lang="scss" scoped>
ul {
  padding-left: 20px;
}
#workshop-item-content {
  width: 80%;
  max-width: 1300px;
  text-align: left;
  padding: 50px 0 50px 0;
}
#workshop-item-content-title {
  font-size: 2em;
}
.closed-button {
  background-color: rgb(185, 185, 185);
  color: #107e79;
}

@media only screen and (max-width: 1024px) {
  #workshop-item-content-title {
    font-size: 2rem !important;
  }
}
@media only screen and (max-width: 450px) {
  #workshop-item-content-title {
    font-size: 1.5rem !important;
  }
}
</style>
