<template>
  <div id="workshop-section-content">
    <workshop-item
      v-for="workshop in workshopArray"
      :key="workshop.workshopId"
      :workshop="workshop"
      @open-modal="registerButtonClicked"
    />
    <modal-sign-up
      :active.sync="showModal"
      @close="onModalChange"
      :submitted="false"
      :workshopValueModal="workshopValueModal"
      :dateTimeString="dateTimeString"
    />

    <div class="alert error" id="error-alert">
      <span class="closebtn" onclick="this.parentElement.style.display='none';">
        &times;
      </span>
      <strong>{{ errorMesssage }}</strong>
    </div>
  </div>
</template>
<script>
import WorkshopItem from "./WorkshopItem";
import ModalSignUp from "@/components/ModalSignUp.vue";
export default {
  name: "workshop-section",
  components: {
    WorkshopItem,
    ModalSignUp,
  },
  data() {
    return {
      workshopArray: null,
      showModal: false,
      workshopValueModal: {},
      dateTimeString: null,
      errorMesssage: null,
    };
  },
  methods: {
    async getWorkshops() {
      const res = await this.$http.get("userworkshop/get").json();
      if (res.error) {
        this.errorMesssage = res.message;
        // to stop the error when there is no workshop
        if (
          this.errorMesssage ==
          "There are no workshops currently. Please try again later"
        ) {
          return 0;
        }
        this.alertElement("error-alert");
      } else {
        this.workshopArray = res.data;
      }
    },
    onModalChange() {
      this.showModal = !this.showModal;
      document.body.classList.toggle("modal-open");
      if (this.showModal) {
        document.getElementById("signup-modal").focus();
      }
    },
    registerButtonClicked(value, dateTimeString) {
      this.dateTimeString = dateTimeString;
      this.workshopValueModal = value;
      this.onModalChange();
    },
    alertElement(id) {
      const alertElement = document.getElementById(id);
      alertElement.classList.add("active");
      setTimeout(function () {
        alertElement.classList.remove("active");
      }, 3000);
    },
  },

  mounted() {
    this.getWorkshops();
  },
};
</script>
<style lang="scss" scoped>
#workshop-section-content {
  background-color: #aec99c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#error-message {
  padding: 30px 0 30px 0;
  color: red;
}
.alert {
  padding: 20px;
  border-radius: 15px;
  color: white;
  position: fixed;
  top: 0;
  z-index: 3;
  display: none;
  &.active {
    display: block;
  }
}
.error {
  background-color: #f44336;
}
.success {
  background-color: #d6e9c6;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}
</style>
