<template>
  <div id="workshop-hero">
    <div id="workshop-page-banner">
      <picture>
        <source
          :srcset="
            require(`@/assets/images/backgrounds/workshop-page-banner.webp`)
          "
          type="image/webp"
        />
        <img
          id="about-us-banner"
          :src="require(`@/assets/images/backgrounds/workshop-page-banner.jpg`)"
          alt="product-pic"
          width="100%"
        />
      </picture>
    </div>
    <p class="workshop-title tangerine-font">Workshops</p>
    <h2 id="coming-soon">Coming to you Soon...</h2>
  </div>
</template>

<script>
export default {
  name: "about-hero",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#workshop-hero {
  background-color: #aec99c;
}
#workshop-page-banner {
  font-size: 0;
}
.workshop-title {
  margin-bottom: 0;
  padding-bottom: 40px;
}
#coming-soon {
  margin: 0;
  padding-bottom: 80px;
}
</style>
