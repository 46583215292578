<template>
  <div id="content">
    <workshop-hero />
    <workshop-section />
  </div>
</template>

<script>
// @ is an alias to /src
import WorkshopHero from "@/components/WorkshopPage/WorkshopHero.vue";
import WorkshopSection from "@/components/WorkshopPage/WorkshopSection.vue";

export default {
  name: "Home",
  components: {
    WorkshopHero,
    WorkshopSection,
  },
  metaInfo: {
    title: "Workshops",
    meta: {
      name: "description",
      content:
        "Sign up for our workshops to learn more on our natural healing methods through mordern technology!",
    },
  },
};
</script>
<style lang="scss" scoped></style>
